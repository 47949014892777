<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <el-form ref="form" :model="form" label-width="100px" label-position="right" size="mini">
          <el-form-item label="问题" prop="title">
            <el-input v-model="form.question" placeholder="请填写问题"></el-input>
          </el-form-item>
          <el-form-item label="答案" prop="content">
            <el-input v-model="form.reply" type="textarea" placeholder="请填写答案"></el-input>
          </el-form-item>
          <el-form-item style="float:right">
            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
        userInfo: {},
        form: {
            guid: '',
            question: '',
            reply: ''
        }
    }
  },
  created() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    // self.getReportDetail(self.$route.query.guid || null)
  },
  methods: {
    // getReportDetail(guid) {
    //   let self = this;
    //   if (guid) {
    //     self.$axios
    //       .get("/api/tree-hole/get/" + guid, {
    //         headers: { token: self.userInfo.token },
    //       })
    //       .then(function(response) {
    //         self.form.guid = response.data.data.guid;
    //         self.form.question = response.data.data.question;
    //         self.form.reply = response.data.data.reply;
    //       })
    //       .catch(function(error) {
    //         self.$message({
    //           message: error.response.data.message,
    //           type: "warning",
    //         });
    //       });
    //   }
    // },
    // 保存
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          let data = JSON.parse(JSON.stringify(self.form));
          self.$axios
            .post("/api/tree-hole/create", data, {
              headers: {
                "content-type": "application/json",
                token: self.userInfo.token,
              },
            })
            .then(function() {
              self.$message({
                message: "操作成功",
                type: "success",
              });
              self.$router.push({ name: "treeHoleQ&AList" });
            })
            .catch(function(error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的管理员信息",
            type: "warning",
          });
          return false;
        }
      });
    },
    
  }
};
</script>

<style>
  .el-transfer-panel{
  width: 30%;
}
</style>
